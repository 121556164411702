.activeLink {
  @apply bg-activeLinkLight dark:bg-activeLinkDark;
}
.inactiveLink {
  @apply bg-surfaceLight hover:bg-activeLinkLight dark:bg-surfaceLighter dark:hover:bg-activeLinkDark;
}

@layer components {
  .pageBlock {
    @apply scrollable h-full items-center rounded-2xl bg-white p-4 shadow-sm dark:bg-surfaceDark;
  }
  .centeredBlock {
    @apply flex h-full w-full items-center justify-center rounded-2xl bg-white shadow-sm dark:bg-surfaceDark;
  }

  .input {
    @apply min-h-10 rounded-xl bg-surfaceLight px-4 font-medium duration-150 focus:bg-activeLinkLight focus:outline-none dark:bg-surfaceLighter dark:text-white dark:focus:bg-activeLinkDark;
  }
  .select {
    @apply h-10 rounded-xl bg-surfaceLight px-4 font-medium duration-150 focus:outline-none dark:bg-surfaceLighter dark:text-white;
    @apply pr-10;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 1em;
  }
  .cellInput {
    @apply noscrollbar rounded-lg bg-surfaceLight px-2 font-medium duration-150 focus:bg-activeLinkLight focus:outline-none dark:bg-surfaceLighter dark:text-white dark:focus:bg-activeLinkDark;
  }
  .phoneInput {
    @apply rounded-xl bg-white px-4 py-2 font-medium duration-150 focus:bg-activeLinkLight focus:outline-slate-300 dark:bg-surfaceDark dark:text-white dark:focus:bg-activeLinkDark dark:focus:outline-slate-600;
  }

  .hoverable {
    @apply duration-150 hover:bg-activeLinkLight dark:hover:bg-activeLinkDark;
  }

  .button {
    @apply flex select-none items-center justify-center rounded-2xl px-3 py-2 text-sm font-semibold duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .littleButton {
    @apply flex select-none items-center justify-center rounded-lg text-sm font-medium duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .iconButton {
    @apply flex select-none items-center justify-center rounded-2xl p-2 font-semibold duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .dangerIconButton {
    @apply flex select-none items-center justify-center rounded-2xl p-2 font-semibold duration-150 hover:bg-[#fee2e2] dark:text-white dark:hover:bg-[#8b5454];
  }
  .broadcastButton {
    @apply flex w-full select-none items-center justify-start rounded-2xl p-3 text-sm font-medium duration-150 hover:bg-activeLinkLight dark:text-white dark:hover:bg-activeLinkDark;
  }
  .broadcastChatButton {
    @apply flex w-fit cursor-pointer select-none items-center justify-center rounded-xl bg-surfaceLight px-4 py-1 font-semibold duration-150 hover:bg-activeLinkLight focus:outline-none dark:bg-surfaceLighter dark:text-white dark:hover:bg-activeLinkDark;
  }

  .baseFilter {
    @apply flex h-10 select-none items-center justify-center rounded-xl px-3 py-1 text-sm duration-150;
  }

  .message {
    @apply rounded-br-xl rounded-tl-xl rounded-tr-xl bg-white dark:bg-surfaceLighter;
  }
  .myMessage {
    @apply rounded-bl-xl rounded-tl-xl rounded-tr-xl bg-[#d9fdd3] dark:bg-[#005c4b];
  }
  .audioMessage {
    @apply rounded-br-xl rounded-tl-xl rounded-tr-xl bg-white dark:bg-surfaceLighter;
  }
  .myAudioMessage {
    @apply rounded-bl-xl rounded-tl-xl rounded-tr-xl bg-[#d9fdd3] dark:bg-[#005c4b];
  }

  .showTip {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 duration-100 ease-out group-hover:scale-0 group-hover:opacity-0;
  }
  .showTipText {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-0 leading-none opacity-0 duration-100 ease-in group-hover:scale-100 group-hover:opacity-100 dark:text-white;
  }
}

.dark .select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ffffff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
}
