.simcard-modal {
  .ant-modal-content {
    @apply bg-white dark:bg-surfaceDark;
  }

  .ant-modal-header {
    @apply bg-white dark:bg-surfaceDark;
  }

  .ant-modal-title {
    @apply text-gray-800 dark:text-white;
  }

  .ant-modal-close {
    @apply text-gray-500 dark:text-gray-400;

    &:hover {
      @apply text-gray-700 dark:text-gray-300;
    }
  }
}

.simcard-collapse {
  .ant-collapse-header {
    @apply text-sm font-medium dark:text-white;
  }

  .ant-collapse-content-box {
    @apply text-sm font-medium dark:text-white;
  }

  .ant-collapse-item {
    @apply rounded-xl bg-surfaceLight dark:bg-surfaceDark;
  }

  .ant-collapse-content {
    @apply rounded-b-xl bg-surfaceLight dark:bg-surfaceDark;
  }
}

.simcard-drawer {
  .ant-drawer-content {
    @apply bg-white dark:bg-surfaceDark;
  }

  .ant-drawer-body {
    @apply bg-white dark:bg-surfaceDark;
  }

  .ant-drawer-header {
    @apply border-b border-gray-200 bg-white dark:border-gray-700 dark:bg-surfaceDark;
  }

  .ant-drawer-title {
    @apply text-gray-800 dark:text-white;
  }

  .ant-drawer-close {
    @apply text-gray-500 dark:text-gray-400;

    &:hover {
      @apply text-gray-700 dark:text-gray-300;
    }
  }
}

.simcard-table {
  @apply rounded-xl bg-white dark:bg-surfaceDark;

  .ant-table {
    @apply bg-transparent;
  }

  .ant-table-thead > tr > th {
    @apply select-none border-b border-gray-200 bg-surfaceLight text-gray-900 dark:border-gray-700 dark:bg-surfaceDark dark:text-white;

    &::before {
      @apply hidden;
    }
  }

  .ant-table-tbody > tr > td {
    @apply border-b border-gray-200 dark:border-gray-700;
  }

  .ant-pagination {
    @apply text-gray-900 dark:text-white;

    .ant-pagination-item {
      @apply border-gray-200 bg-surfaceLight dark:border-gray-700 dark:bg-surfaceLighter;

      a {
        @apply text-gray-900 dark:text-white;
      }

      &-active {
        @apply border-blue-500 dark:border-blue-400;
      }
    }
  }
}

.ant-popover {
  @apply bg-white dark:bg-surfaceDark;

  .ant-popover-inner {
    @apply bg-white dark:bg-surfaceDark;
  }

  .ant-popover-title {
    @apply border-b border-gray-200 text-gray-900 dark:border-gray-700 dark:text-gray-100;
  }

  .ant-popover-inner-content {
    @apply text-gray-800 dark:text-gray-200;
  }

  .ant-popover-arrow-content {
    @apply bg-white dark:bg-surfaceDark;

    &::before {
      @apply bg-white dark:bg-surfaceDark;
    }
  }
}

.collapse-container {
  .ant-collapse-content {
    @apply border-t-2 border-gray-200 dark:border-gray-700;
  }

  .ant-collapse-header {
    @apply rounded-xl border-none bg-surfaceDarker dark:bg-surfaceLighter;
  }

  .ant-collapse-item {
    @apply rounded-xl border-none bg-surfaceDarker dark:bg-surfaceLighter;
  }

  .ant-collapse-content-box {
    @apply bg-surfaceDarker dark:bg-surfaceLighter;
  }
}
