@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.scss';
@import './tailwind.scss';
@import './antdesign.scss';

* {
  font-family: Noto Sans;
}

img {
  user-select: none;
}

.noscrollbar {
  scrollbar-width: none;
  overflow-y: scroll;
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: auto;
}
.xscrollable {
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-gutter: auto;
}
.scrollable::-webkit-scrollbar {
  width: 8px;
  overflow-y: scroll;
}
.scrollable::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-[#e1e1e1] dark:bg-slate-600;
}
.scrollable::-webkit-scrollbar-track {
  margin: 10px 0;
  background-color: transparent;
}
.xscrollable::-webkit-scrollbar {
  height: 4px;
  overflow-y: scroll;
}
.xscrollable::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-[#e1e1e1] dark:bg-slate-600;
}
.xscrollable::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 0 20px;
}
.smooth {
  @apply transition-all duration-100 ease-in;
}

:root {
  @apply bg-surfaceLight dark:bg-surfaceLighter;
}

#chat-window,
.broadcastChat-window {
  background-image: url('../../shared/assets/images/messageBg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

:root.dark #chat-window,
:root.dark .broadcastChat-window {
  background-image: url('../../shared/assets/images/darkMessageBg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #e1e1e6 inset;
}

::placeholder {
  user-select: none;
}

input {
  transition: background-color 0.1s ease-out;
}

input:focus {
  outline: none;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.logo-animated {
  path {
    fill: transparent;
    stroke: #0070b9;
    stroke-width: 3;
    animation: fillLogo 1s ease forwards;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes fillLogo {
  0% {
    fill: transparent;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
  40% {
    fill: transparent;
    stroke-dashoffset: 0;
  }
  90%,
  100% {
    fill: #0070b9;
    stroke-dashoffset: 0;
  }
}

.logo-text-animated {
  animation: hideCursor 0.5s ease-out forwards;
  animation-delay: 0.2s;
}

@keyframes hideCursor {
  to {
    border-right-color: transparent;
  }
}
